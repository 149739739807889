 import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';

//import {formatDate} from '@angular/common';
declare var $: any
declare var echarts: any;
@Component({
  selector: 'app-personeller',
  templateUrl: './personeller.component.html',
  styleUrls: ['./personeller.component.scss']
})
export class PersonellerComponent implements OnInit {
  chart: any
  ekle: any = {}
  guncelle: any = { cinsiyet: "Erkek" }
  id: any
  table: any
  constructor(private httpService: HttpService) { }

  chartData: [100, 250, 300, 15]

  personelInfo: any = {}

  ngOnInit() {

    
    $(document).ready(function(){
      // Show the Modal on load
      $("#myModal").modal("show");
        
      // Hide the Modal
      // $("#myBtn").click(function(){
      //   $("#myModal").modal("hide");
      // });
    });
    // this.getPersonel();
    // this.pastaGrafik();
    // // this.lineChart()
    // // this.tableDraw();
    // this.genelPerformans();
    // this.pieChart();
    // console.log($('#personelDate')[0].valueAsNumber = new Date().getTime())

    $(".dial").knob({
      'min': 0,
      'max': 100,
      'thickness': 0.1,
      'format': function(value) { return "düz" }
    });
    $(".knob").knob({
      draw: function() {
        if ("tron" == this.$.data("skin")) {
          this.cursorExt = .3;
          var t, i = this.arc(this.cv);
          return this.g.lineWidth = this.lineWidth, this.o.displayPrevious && (t = this.arc(this.v), this.g.beginPath(), this.g.strokeStyle = this.pColor, this.g.arc(this.xy, this.xy, this.radius - this.lineWidth, t.s, t.e, t.d), this.g.stroke()), this.g.beginPath(), this.g.strokeStyle = this.o.fgColor, this.g.arc(this.xy, this.xy, this.radius - this.lineWidth, i.s, i.e, i.d), this.g.stroke(), this.g.lineWidth = 2, this.g.beginPath(), this.g.strokeStyle = this.o.fgColor, this.g.arc(this.xy, this.xy, this.radius - this.lineWidth + 1 + 2 * this.lineWidth / 3, 0, 2 * Math.PI, !1), this.g.stroke(), !1
        }
      }
    });

  }

  genelPerformans() {
    var option = {
      grid: {
        left: 40,
        top: 30,
        right: 40,
        bottom: 20
      },
      legend: {
        orient: 'horizontal',
        top: true,
        data: ['Personel Verimi', 'Fabrika Verimi'],
      },
      tooltip: {

        trigger: 'item',
        formatter: "{a} </br>{b} : {c}"
      },

      xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        name: 'Personel Verimi',
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line',
        smooth: true
      },

      {
name: 'Fabrika Verimi',
        data: [820, 1, 901, 9334, 1290, 13330, 1320],
        type: 'line',
        smooth: true
      }]
    };



    this.chart = echarts.init(document.getElementById('performansChart'));


    this.chart.setOption(option);

  }

  pastaGrafik() {
    var option = {

      tooltip: {
        trigger: 'item',
        formatter: "{a} <br/>{b} : {c} ({d}%)"
      },
      legend: {
        orient: 'horizontal',
        top: true,
        data: ['düz', 'overlok', 'reçme', 'körDikiş', 'karyoka']
      },
      series: [
        {
          name: 'verim',
          type: 'pie',
          radius: '55%',
          center: ['50%', '60%'],
          data: [
            { value: 335, name: 'düz' },
            { value: 310, name: 'overlok' },
            { value: 234, name: 'reçme' },
            { value: 135, name: 'körDikiş' },
            { value: 1548, name: 'karyoka' }
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };




    this.chart = echarts.init(document.getElementById('pastaChart'));


    this.chart.setOption(option);

  }

  pieChart() {

    var option = {
      // title: {
      //     text: 'Verim Takip'
      // },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        orient: 'horizontal',
        top: true,
        data: ['Düz', 'Overlok', 'Reçme', 'Kör Dikiş', 'Karyoka'],
        padding: [0, 0, -30, 0]
      },
      grid: {

        left: '0%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      //     toolbox: {
      //         feature: {
      //             saveAsImage: {
      //  title:'Kaydet',
      // padding: [10, 20, 0, 0]
      // },
      //
      //
      //         },
      //
      //     },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        data: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz',]
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'düz',
          type: 'line',

          data: [0, 132, 101, 134, 90, 230, 210],
          smooth: true
        },
        {
          name: 'overlok',
          type: 'line',

          data: [0, 182, 191, 234, 290, 330, 310],
          smooth: true
        },
        {
          name: 'reçme',
          type: 'line',

          data: [100, 232, 201, 154, 190, 330, 410],
          smooth: true
        },
        {
          name: 'kör dikiş',
          type: 'line',

          data: [0, 332, 301, 334, 390, 330, 320],
          smooth: true
        },
        {
          name: 'karyoka',
          type: 'line',

          data: [0, 932, 901, 934, 1290, 1330, 320],
          smooth: true
        }
      ]
    };

    this.chart = echarts.init(document.getElementById('pieChart'));


    this.chart.setOption(option);

  }

  tableDraw(data) {
    let q = this
    $(document).ready(function() {

      var dataSrc = [];
      q.table = $('#personeller').DataTable({
        autoFill: true,

        "data": data,


        // dom: 'Bfrtip',
        // dom: '"<"row"<"col-sm-6"l><"col-sm-6 text-right"<"#dt-btn"B>f>>" ' +
        //   '<"row"<"col-sm-12"tr>>' +
        //   '<"row"<"col-sm-6"i><"col-sm-6 text-right"p>>',
        // buttons: [
        //   {
        //     extend: 'csv',
        //     // filename: 'file_name',
        //     text: 'CSV',
        //     exportOptions: {
        //       modifier: {
        //         search: 'none'
        //       }
        //     }
        //   },
        //   {
        //     extend: 'pdf',
        //     // filename: 'file_name',
        //     text: 'PDF',
        //     exportOptions: {
        //       modifier: {
        //         search: 'none'
        //       }
        //     }
        //   },
        //   {
        //     extend: 'print',
        //     text: 'Print',
        //     exportOptions: {
        //       modifier: {
        //         search: 'none'
        //       }
        //     }
        //   },
        //   {
        //     extend: 'excel',
        //     text: 'excel',
        //     exportOptions: {
        //       modifier: {
        //         search: 'none'
        //       }
        //     }
        //   }
        // ],
        // buttons: [
        //         'copy', 'excel','csv','print','pdf'
        //     ],
        columns: [{
          "data": "hatNo",
          "title": "Bant",
          "defaultContent": "-"
        },
        {
          "data": "sicilNo",
          "title": "Sicil No",
          "defaultContent": "-"
        },
        {
          "data": "adi",
          "title": "Adı Soyadı",
          "render": function(data, type, row) {

            return data + " " + row.soyadi
          },
          "defaultContent": "-"

        },
        {
          "data": "sicilNo",
          "title": "Foto",
          "defaultContent": "-",
          "render": function(data, task, row) {
            return '<ul class="list-unstyled order-list"><li class="avatar avatar-sm" style="margin-right:15px;"><img class="rounded-circle" src="../../assets/images/avatar.png" alt="user" title="Berkay"></li><ul>'

          }
        },
        {
          "data": "gorevi",
          "title": "Görevi",
          "defaultContent": "-"
        },

        {
          "data": "cinsiyet",
          "title": "Cinsiyet",
          "defaultContent": "-"
        },
        {
          "data": "iseBaslamaTarihi",
          "title": "İşe Giriş Tarihi",
          "render": function(data, type, row) {
            if (type === 'display' || type === 'filter') {
              var d = new Date(data);
              return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
            }


            return data;
          },
          "defaultContent": "-"


          // function(data,type,row){
          //                 //  return row.iseBaslamaTarihi= formatDate(new/* row.iseBaslamaTarihi.*/Date(), 'yyyy/MM/dd', 'en');
          //                 //var dateSplit = data.split('/');
          //                 return  data.iseBaslamaTarihi.getDate() +'/'+ data.iseBaslamaTarihi.getMonth() +'/'+ data.iseBaslamaTarihi.getFullYear();
          //
          //
          // },
          // "iDataSort": 1
        },
        {
          "data": "sicilNo",
          "title": "Çalışma",
          "defaultContent": "-"
        },
        {
          "data": "sicilNo",
          "title": "Kayıp",
          "defaultContent": "-"
        },
        {
          "data": "sicilNo",
          "title": "Mesai",
          "defaultContent": "-"
        },
        {
          "data": "maas",
          "title": "Maaş",
          "defaultContent": "-"
        },
        {
          "data": "_id",
          "title": "Bilgi",
          "defaultContent": "-",
          "render": function(data, task, row) {
            return '<button class="btn btn-primary h-auto" data-toggle="modal" data-target=".bd-example-modal-lg" data-id=' + data + '>Detay</i> </button>'

          }
        },
        //,{
        //   "data": "firstname",
        //   "title": "FirstName"
        // }
        {
          "data": "_id",
          "title": "",
          "defaultContent": "-",
          "render": function(data, task, row) {
            return '<button class="btn tblActnBtn guncelle h-auto" data-toggle="modal" data-target="#guncelleModal" data-id=' + data + '><i class="fas fa-edit mr-2"></i> </button>'

          },
          "autoWidth": false,
          "sorting": false,
          "orderable": false
        },
        {
          "data": "_id",
          "title": "",
          "render": function(data, task, row) {

            return '<button class="btn tblActnBtn h-auto izin" data-id=' + data + '><i class="fas fa-bed mr-2"></i></button>'
          }
},


        {
          "data": "_id",
          "title": "",
          "render": function(data, task, row) {

            return '<button class="btn tblActnBtn h-auto sil" data-id=' + data + ' (click)="silme(' + data + ')"><i class="fas fa-trash-alt mr-2"></i></button>'
          },
          "autoWidth": false,
          "sorting": false,
          "orderable": false,
          "defaultContent": "-"
        }
        ],
        'initComplete': function() {


          var api = this.api();

          // Populate a dataset for autocomplete functionality
          // using data from first, second and third columns
          api.cells('tr', [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]).every(function() {
            // Get cell data as plain text
            var data = $('<div>').html(this.data()).text();
            if (dataSrc.indexOf(data) === -1) { dataSrc.push(data); }
          });

          // Sort dataset alphabetically
          dataSrc.sort();

          // Initialize
          // $('.dataTables_filter input[type="search"]', api.table().container())
          //   .typeahead({
          //     source: dataSrc,
          //     afterSelect: function(value) {
          //       api.search(value).draw();
          //     }
          //   }
          //   );
        },
        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "Hepsi"]],
        // "lengthMenu": [[25], [ "25"]],
        // "iDisplayLength": 50,

        "language": {

          "sDecimal": ",",
          "sEmptyTable": "Tabloda herhangi bir veri mevcut değil",
          "sInfo": "_TOTAL_ kayıttan _START_ - _END_ arasındaki kayıtlar gösteriliyor",
          "sInfoEmpty": "Kayıt yok",
          "sInfoFiltered": "(_MAX_ kayıt içerisinden bulunan)",
          "sInfoPostFix": "",
          "sInfoThousands": ".",
          "sLengthMenu": "Sayfada _MENU_ kayıt göster",
          "sLoadingRecords": "Yükleniyor...",
          "sProcessing": "İşleniyor...",
          "sSearch": "Ara:",
          "sZeroRecords": "Eşleşen kayıt bulunamadı",
          "oPaginate": {
            "sFirst": "İlk",
            "sLast": "Son",
            "sNext": "Sonraki",
            "sPrevious": "Önceki"
          },
          "oAria": {
            "sSortAscending": ": artan sütun sıralamasını aktifleştir",
            "sSortDescending": ": azalan sütun sıralamasını aktifleştir"
          },
          "select": {
            "rows": {
              "_": "%d kayıt seçildi",
              "0": "",
              "1": "1 kayıt seçildi"
            }
          }
        }





      });


      if (true) {
        q.table.on('click', 'button.sil', function(e) {

          console.log("sil")
          e.preventDefault();
          q.silme($(this).data().id)
          console.log($(this).data().id)

        });

        q.table.on('click', 'button.guncelle', function(e) {
          console.log("guncelle")

          e.preventDefault();
          for (let i = 0; i < q.personelInfo.data.length; i++) {
            if (q.personelInfo.data[i]._id == $(this).data().id) {
              q.guncelle = q.personelInfo.data[i];
              q.guncelle.iseBaslamaTarihi = q.guncelle.iseBaslamaTarihi.split("T")[0];

              break;
            };
          }
          console.log(q.guncelle)

        });
      }



      $('.dataTables_length').addClass('bs-select');

      $('#personeller').on('length.dt', function(e, settings, len) {
        console.log('New page length: ' + len);
        q.dataTableSearch(len)
      });
      //
      // console.log($('#bantDurum'))

      let table = $('#personeller').DataTable();

      var buttons = new $.fn.dataTable.Buttons(table, {

        // dom: '"<"row"<"col-sm-6"l><"col-sm-6 text-right"<"#dt-btn"B>f>>" ' +
        // 					'<"row"<"col-sm-12"tr>>' +
        // 					'<"row"<"col-sm-6"i><"col-sm-6 text-right"p>>',

        buttons: [
          'copy', 'excel', 'print', 'pdf'
        ],

      }).container().appendTo($('#btnPrint'));


      table.on('search.dt', function() {
        console.log('Currently applied global search: ' + table.search());
        console.log(table.search())

        // var interval = setInterval(function() {
        //   console.log(table.search() + "table içinde search")
        //   q.dataTableSearch(table.search())
        // }, 5000)

            setTimeout(function(){
              console.log(table.search() + "table içinde search")
              q.dataTableSearch(table.search())
        }, 1000);

      });

      $('#personeller').on('page.dt', function() {
        var info = table.page.info();


        console.log('Showing page: ' + info.page + ' of ' + info.pages);
      });

    });


  }

  lineChart() {

    let q = this

    let option = {



      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0
      },
      tooltip: {

        trigger: 'item',
        formatter: "{a} </br>{b} : {c}"
      },

      backgroundColor: '#17a2b8',

      xAxis: {
        show: false,
        type: 'category',
        boundaryGap: false,

      },
      yAxis: {
        type: 'value',
        show: false
      },
      series: [{
        name: "Verim",
        smooth: true,
        color: '#fff',
        data: [820, 932, 901, 1000, 1290, 1330, 1320],
        type: 'line',
        areaStyle: {}
      }]
    };

    this.chart = echarts.init(document.getElementById('lineChart'));


    this.chart.setOption(option);

  }



  getPersonel() {
    this.httpService.getPersonel(200, 1).subscribe(
      (data: any) => {
        if (data.error) {
          alert(data.error)

        } else {

          console.log("get personel")
          console.log(data);
          this.personelInfo = data;
console.log(this.personelInfo)
          for (let i = 0; i < this.personelInfo.data.length; i++) {
            if (this.personelInfo.data[i].aktif === false) {
              this.personelInfo.data.splice(i, 1)
              i--;
            };
          }
          this.tableDraw(this.personelInfo.data)

        }
      }
    )
  }

  dataTableSearch(search) {
    this.httpService.dataTableArama(search).subscribe(
      (data: any): any => {
        if (data.error) {
          alert("table search error")
        } else {
          console.log(data)
          console.log("table search here!")
        }
      }
    )
  }

  silme(_id) {
    console.log("silme çalıştı " + _id)
    this.httpService.personelSil(_id).subscribe(
      (data: any) => {
        if (data.error) {
          alert(data.error)

        } else {

          // this.reloadPage()

        }
      }
    )
  }


  ekleme(_id) {
    var d = this
    console.log(_id)
    //console.log(d.toISOString())
    // console.log( $('#personelDate')[0].valueAsNumber = d.toISOString())
    this.httpService.personelEkle(_id).subscribe(
      (data: any) => {
        if (data.error) {
          console.log(_id)

          console.log(this.ekle)
          alert(data.error)

        } else if (data.ok) {
          console.log(this.ekle)
          this.reloadPage()

        }
      }
    )
  }

  guncelleme(guncelle) {
    //this.guncelle = { _id:}
    console.log(guncelle)


    guncelle.iseBaslamaTarihi = new Date(guncelle.iseBaslamaTarihi);
    var d = guncelle.iseBaslamaTarihi.toISOString()
    guncelle.iseBaslamaTarihi = d
    console.log(guncelle.iseBaslamaTarihi)
    //guncelle.iseBaslamaTarihi = new Date(guncelle.iseBaslamaTarihi);


    this.httpService.personelGuncelle(guncelle).subscribe(
      (data: any) => {
        if (data.error) {
          alert(data.error)

        } else {

          this.reloadPage()

        }
      }
    )

  }


  reloadPage() {
    window.location.reload();
  }





}
