import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import { NgForm } from '@angular/forms';

declare var echarts: any;

declare var $:any;
@Component({
  selector: 'app-calismasaatleri',
  templateUrl: './calismasaatleri.component.html',
  styleUrls: ['./calismasaatleri.component.scss']
})
export class CalismasaatleriComponent implements OnInit {
  chart: any
  tatil: any = {}
  //table: any
  ekle: any = {}
  guncelle: any = { _id: 0 }
  id: any
  mola: any = {}
  sendikaMola: any = {
wc:{
sayisi:1,
suresi:1
},
yemek:{
sayisi:1,
suresi:1
},
mola:{
sayisi:1,
suresi:1
}

}
  sendikaMolaArray:any=[]
chartMola:any={}

haftalikProgram: any = {
pazartesi:{
  vardiya1:{
    baslangic:0,
    bitis:0
  },
  vardiya2:{
    baslangic:0,
    bitis:0
  },
  vardiya3:{
    baslangic:0,
    bitis:0
  },

},

sali:{
  vardiya1:{
    baslangic:0,
    bitis:0
  },
  vardiya2:{
    baslangic:0,
    bitis:0
  },
  vardiya3:{
    baslangic:0,
    bitis:0
  },

},

carsamba:{
  vardiya1:{
    baslangic:0,
    bitis:0
  },
  vardiya2:{
    baslangic:0,
    bitis:0
  },
  vardiya3:{
    baslangic:0,
    bitis:0
  },

},

persembe:{
  vardiya1:{
    baslangic:0,
    bitis:0
  },
  vardiya2:{
    baslangic:0,
    bitis:0
  },
  vardiya3:{
    baslangic:0,
    bitis:0
  },

},

cuma:{
  vardiya1:{
    baslangic:0,
    bitis:0
  },
  vardiya2:{
    baslangic:0,
    bitis:0
  },
  vardiya3:{
    baslangic:0,
    bitis:0
  },

},

cumartesi:{
  vardiya1:{
    baslangic:0,
    bitis:0
  },
  vardiya2:{
    baslangic:0,
    bitis:0
  },
  vardiya3:{
    baslangic:0,
    bitis:0
  },

},

pazar:{
  vardiya1:{
    baslangic:0,
    bitis:0
  },
  vardiya2:{
    baslangic:0,
    bitis:0
  },
  vardiya3:{
    baslangic:0,
    bitis:0
  },

},

}

  constructor(private httpService: HttpService) { }

  ngOnInit() {

    
    // this.getMola();
    //
    // $(function() {
    //     $('#tarih').datetimepicker({
    //       format: 'HH:mm'
    //     });
    //   });

    $(document).ready(function(){
      // Show the Modal on load
      $("#myModal").modal("show");
        
      // Hide the Modal
      // $("#myBtn").click(function(){
      //   $("#myModal").modal("hide");
      // });
    });
  }


  tatilSilme(_id) {

    this.httpService.tatilSil(_id).subscribe(
      (data: any) => {
        if (data.error) {
          alert(data.error)

        } else {

          this.reloadPage()

        }
      }
    )
  }


  tatilEkleme(_id) {


    this.httpService.tatilEkle(_id).subscribe(
      (data: any) => {
        if (data.error) {
          alert(data.error)

        } else {

          this.reloadPage()

        }
      }
    )
  }

  tatilGuncelleme(guncelle) {
    //this.guncelle = { _id:}
    this.httpService.tatilGuncelle(guncelle).subscribe(
      (data: any) => {
        if (data.error) {
          alert(data.error)

        } else {

          this.reloadPage()

        }
      }
    )

  }

  haftalikCalismaProgrami(haftalikProgram) {

    this.httpService.haftalikCalismaProgrami(haftalikProgram).subscribe(
      (data: any) => {
        if (data.error) {
          alert(data.error)

        } else {

          // this.reloadPage()

        }
      }
    )



  }

  molaGuncelle(mola) {

    this.sendikaMolaArray[0] = this.sendikaMola.wc
this.sendikaMolaArray[0].suresi = this.sendikaMola.wc.suresi *60
    this.sendikaMolaArray[1] = this.sendikaMola.mola
this.sendikaMolaArray[1].suresi = this.sendikaMola.mola.suresi *60
    this.sendikaMolaArray[2] = this.sendikaMola.yemek
this.sendikaMolaArray[2].suresi = this.sendikaMola.yemek.suresi*60

  


for (let i = 0; i < this.sendikaMolaArray.length; i++) {
  this.httpService.molaGuncelle(this.sendikaMolaArray[i]).subscribe(
    (data: any) => {
      if (data.error) {
      //  alert(data.error)

      } else {

        this.reloadPage()

      }
    }
  )
}


  }

  getMola() {
    var item = "randomString"
    this.httpService.getMolaBilgileri(item).subscribe(
      (data: any) => {
        if (data.error) {
          alert(data.error)

        } else {
          
          //this.reloadPage()


          this.sendikaMola.wc = data[0]
          this.sendikaMola.mola = data[1]
          this.sendikaMola.yemek = data[2]
          this.sendikaMola.wc.suresi = this.sendikaMola.wc.suresi / 60
          this.sendikaMola.mola.suresi = this.sendikaMola.mola.suresi / 60
          this.sendikaMola.yemek.suresi = this.sendikaMola.yemek.suresi / 60
          this.mesaiPlan();


        }
      }
    )

  }

  reloadPage() {
    window.location.reload();
  }

tumuneUygula(){


let a = this.haftalikProgram.pazartesi;
 this.haftalikProgram.sali.vardiya1.baslangic = a.vardiya1.baslangic;
 this.haftalikProgram.sali.vardiya1.bitis = a.vardiya1.bitis;
 this.haftalikProgram.sali.vardiya2.baslangic = a.vardiya2.baslangic;
 this.haftalikProgram.sali.vardiya2.bitis = a.vardiya2.bitis;
  this.haftalikProgram.sali.vardiya3.baslangic = a.vardiya3.baslangic;
  this.haftalikProgram.sali.vardiya3.bitis = a.vardiya3.bitis;

  this.haftalikProgram.carsamba.vardiya1.baslangic = a.vardiya1.baslangic;
  this.haftalikProgram.carsamba.vardiya1.bitis = a.vardiya1.bitis;
  this.haftalikProgram.carsamba.vardiya2.baslangic = a.vardiya2.baslangic;
  this.haftalikProgram.carsamba.vardiya2.bitis = a.vardiya2.bitis;
   this.haftalikProgram.carsamba.vardiya3.baslangic = a.vardiya3.baslangic;
   this.haftalikProgram.carsamba.vardiya3.bitis = a.vardiya3.bitis;

   this.haftalikProgram.persembe.vardiya1.baslangic = a.vardiya1.baslangic;
   this.haftalikProgram.persembe.vardiya1.bitis = a.vardiya1.bitis;
   this.haftalikProgram.persembe.vardiya2.baslangic = a.vardiya2.baslangic;
   this.haftalikProgram.persembe.vardiya2.bitis = a.vardiya2.bitis;
    this.haftalikProgram.persembe.vardiya3.baslangic = a.vardiya3.baslangic;
    this.haftalikProgram.persembe.vardiya3.bitis = a.vardiya3.bitis;

    this.haftalikProgram.cuma.vardiya1.baslangic = a.vardiya1.baslangic;
    this.haftalikProgram.cuma.vardiya1.bitis = a.vardiya1.bitis;
    this.haftalikProgram.cuma.vardiya2.baslangic = a.vardiya2.baslangic;
    this.haftalikProgram.cuma.vardiya2.bitis = a.vardiya2.bitis;
     this.haftalikProgram.cuma.vardiya3.baslangic = a.vardiya3.baslangic;
     this.haftalikProgram.cuma.vardiya3.bitis = a.vardiya3.bitis;


}


  mesaiPlan() {

    let q = this
    let option = {
      tooltip: {
        trigger: 'item',
        formatter: " {b}: {c} {a} ({d}%)"
      },
      legend: {
        orient: 'vertical',
        x: 'left',
        y: 'middle',
        data: ['Aktif Çalışma', 'Molalar', 'Yemek'],
        itemGap: 30
      },

      series: [
        {
          position: 'right',

          color: ['#ffc107', '#20c997', '#17a2b8'],
          name: 'dakika',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: true,

          label: {
            normal: {
              show: false,
              position: 'right'
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: '20',
                fontWeight: 'bold'
              }
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: [
            { value: 335, name: 'Aktif Çalışma' },
            { value: this.sendikaMola.mola.suresi * this.sendikaMola.mola.sayisi, name: 'Molalar' },
            { value: this.sendikaMola.yemek.suresi * this.sendikaMola.yemek.sayisi, name: 'Yemek' },

          ]
        }
      ]
    };

    this.chart = echarts.init(document.getElementById('mesaiChart'));

    this.chart.setOption(option);

  }
}
