import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
declare var $: any
@Component({
  selector: 'app-makineler',
  templateUrl: './makineler.component.html',
  styleUrls: ['./makineler.component.sass']
})
export class MakinelerComponent implements OnInit {
  makine: any = {}
  table: any
  ekle: any = {}
  guncelle: any = { _id: 0 }
  id:any
  calismaSuresi:any
  constructor(private httpService: HttpService) { }

  ngOnInit() {
    // this.calismaSaati();
    // this.getMakine();

    $(document).ready(function(){
      // Show the Modal on load
      $("#myModal").modal("show");
        
      // Hide the Modal
      // $("#myBtn").click(function(){
      //   $("#myModal").modal("hide");
      // });
    });
  }

  getMakine() {
    this.httpService.getMakine(20, 1).subscribe(
      (data: any) => {
        if (data.error) {
          alert(data.error)

        } else {

          console.log("get makine")
          console.log(data);
          this.makine = data;
          console.log(this.makine.data)
// console.log(this.makine.bilgi[0].calisabilir)

          for (let i = 0; i < this.makine.data.length; i++) {
            if (this.makine.data[i].calisabilir === false) {
              this.makine.data.splice(i,1)
                i--;
            };

          }
          this.makineler(this.makine.data)

        }
      }
    )
  }

  silme(_id) {
    this.httpService.makineSil(_id).subscribe(
      (data: any) => {
        if (data.error) {
          alert(data.error)

        } else {

          this.reloadPage()

        }
      }
    )
  }


  ekleme(_id) {
    this.httpService.makineEkle(_id).subscribe(
      (data: any) => {
        if (data.error) {
          alert(data.error)

        } else {

          this.reloadPage()

        }
      }
    )
  }

  guncelleme(guncelle) {
    //this.guncelle = { _id:}
    this.httpService.makineGuncelle(guncelle).subscribe(
      (data: any) => {
        if (data.error) {
          alert(data.error)

        } else {

          this.reloadPage()

        }
      }
    )

  }

calismaSaati(){
var q = "cicikus"
  this.httpService.makineCalismaSuresi(q).subscribe(
    (data: any) => {
      if (data.error) {
        alert(data.error)

      } else {
          console.log(data)
        // this.calismaSuresi =

      }
    }
  )
}

dataTableSearch(search) {
  this.httpService.dataTableArama(search).subscribe(
    (data: any): any => {
      if (data.error) {
        alert("table search error")
      } else {
        console.log(data)
        console.log("table search here!")
      }
    }
  )
}

  makineler(data) {

    let q = this
    $(document).ready(function() {
      var dataSrc = [];
      q.table = $('#makineler').DataTable({

         "data": data,
        // dom: '"<"row"<"col-sm-6"l><"col-sm-6 text-right"<"#dt-btn"B>f>>" ' +
        //   '<"row"<"col-sm-12"tr>>' +
        //   '<"row"<"col-sm-6"i><"col-sm-6 text-right"p>>',
        // buttons: [
        //         'copy', 'excel','csv','print','pdf'
        //     ],

        columns: [{
          "data": "makineNo",
          "title": "Makine No",
          "defaultContent": "-"
        },
        {
          "data": "makineAdi",
          "title": "Makine Kodu",
          "defaultContent": "-"
        },
        {
          "data": "makineTuru",
          "title": "Türü",
          "defaultContent": "-"

        },
        {
          "data": "calisabilir",
          "title": "Çalışma Süresi",
          "defaultContent": "-"
        },
        {
          "data": "arizaSuresi",
          "title": "Arıza Süresi",
          // "type": function(data, type, row) {
          //   row.iseBaslamaTarihi = new Date().getDate
          // },
          "defaultContent": "-"
        },
        {
          "data": "verim",
          "title": "Verim",
          "defaultContent": "-"
        },
        {
          "data": "sensorKodu",
          "title": "1.Sensör MAC Adresi",
          "defaultContent": "-"
        },
        // {
        //   "data": "role",
        //   "title": "Röle MAC Adresi",
        //   "defaultContent": "-"
        // },
        // {
        //   "data": "beacon",
        //   "title": "Beacon MAC Adresi",
        //   "defaultContent": "-"
        // },
        // {
        // "data": "_id",
        // "title": "Actions",
        // "render": function(data, type, row){
        //
        //     return "<a href='#' title='Approve This User' class='on-default approve-row' data-id='" + data + "'><i class='fa fa-thumbs-up'></i></a>              <a href='#' title='Delete This User' class='on-default remove-row'  data-id='" + data + "'><i class='fa fa-trash-o'></i></a>";
        //
        // },
        // "sorting": false,
        // "orderable": false
        // }
        {
          "data": "_id",
          "title": "",
          "render": function(data, task, row) {
            return '<button class="btn tblActnBtn h-auto guncelle" data-toggle="modal" data-target="#guncelleModal" data-id=' + data + '><i class="fas fa-edit mr-2"></i> </button>'

          },
          "autoWidth": false,
          "sorting": false,
          "orderable": false
        },
        {
          "data": "_id",
          "title": "",
          "render": function(data, task, row) {
            return '<button class="btn tblActnBtn h-auto sil" data-id=' + data + ' (click)="silme(' + data + ')"><i class="fas fa-trash-alt mr-2"></i></button>'

          },
          "autoWidth": false,
          "sorting": false,
          "orderable": false
        }
        ],


        // 'initComplete': function() {
        //   var api = this.api();
        //
        //   // Populate a dataset for autocomplete functionality
        //   // using data from first, second and third columns
        //   api.cells('tr', [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]).every(function() {
        //     // Get cell data as plain text
        //     var data = $('<div>').html(this.data()).text();
        //     if (dataSrc.indexOf(data) === -1) { dataSrc.push(data); }
        //   });
        //
        //   // Sort dataset alphabetically
        //   dataSrc.sort();
        //
        //   // Initialize Typeahead plug-in
        //   $('.dataTables_filter input[type="search"]', api.table().container())
        //     .typeahead({
        //       source: dataSrc,
        //       afterSelect: function(value) {
        //         api.search(value).draw();
        //       }
        //     }
        //     );
        // },




        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "Hepsi"]],

        "language": {

          "sDecimal": ",",
          "sEmptyTable": "Tabloda herhangi bir veri mevcut değil",
          "sInfo": "_TOTAL_ kayıttan _START_ - _END_ arasındaki kayıtlar gösteriliyor",
          "sInfoEmpty": "Kayıt yok",
          "sInfoFiltered": "(_MAX_ kayıt içerisinden bulunan)",
          "sInfoPostFix": "",
          "sInfoThousands": ".",
          "sLengthMenu": "Sayfada _MENU_ kayıt göster",
          "sLoadingRecords": "Yükleniyor...",
          "sProcessing": "İşleniyor...",
          "sSearch": "Ara:",
          "sZeroRecords": "Eşleşen kayıt bulunamadı",
          "oPaginate": {
            "sFirst": "İlk",
            "sLast": "Son",
            "sNext": "Sonraki",
            "sPrevious": "Önceki"
          },
          "oAria": {
            "sSortAscending": ": artan sütun sıralamasını aktifleştir",
            "sSortDescending": ": azalan sütun sıralamasını aktifleştir"
          },
          "select": {
            "rows": {
              "_": "%d kayıt seçildi",
              "0": "",
              "1": "1 kayıt seçildi"
            }
          }
        }





      });

      if (true) {
        q.table.on('click', 'button.sil', function(e) {
          e.preventDefault();
          //console.log(q.silme($(q).data()._id))
          q.silme($(this).data().id)
          console.log($(this).data().id)
          //window.location.reload();

        });

        q.table.on('click', 'button.guncelle', function(e) {
          e.preventDefault();
           this.id = $(this).data().id
           for (let i = 0; i < q.makine.data.length; i++) {
               if (q.makine.data[i]._id == $(this).data().id) {
                   q.guncelle = q.makine.data[i];
                   break;
               };
console.log(q.guncelle)
           }
        })

        // this.table.on('click', 'a.remove-row', function ( e ) {
        //   e.preventDefault();
        //   this.deleteUser($(this).data().id);
        // } );

        //this.oneTable = true;
      }

      $('.dataTables_length').addClass('bs-select');

      $('#makineler').on('length.dt', function(e, settings, len) {
        console.log('New page length: ' + len);
        q.dataTableSearch(len)
      });
      //
      // console.log($('#bantDurum'))

      let table = $('#makineler').DataTable();

      var buttons = new $.fn.dataTable.Buttons(table, {

        // dom: '"<"row"<"col-sm-6"l><"col-sm-6 text-right"<"#dt-btn"B>f>>" ' +
        // 					'<"row"<"col-sm-12"tr>>' +
        // 					'<"row"<"col-sm-6"i><"col-sm-6 text-right"p>>',

        buttons: [
          'copy', 'excel', 'print', 'pdf'
        ],

      }).container().appendTo($('#btnPrint'));


      table.on('search.dt', function() {
        console.log('Currently applied global search: ' + table.search());
        console.log(table.search())

        // var interval = setInterval(function() {
        //   console.log(table.search() + "table içinde search")
        //   q.dataTableSearch(table.search())
        // }, 5000)

            setTimeout(function(){
              console.log(table.search() + "table içinde search")
              q.dataTableSearch(table.search())
        }, 1000);

      });

      $('#makineler').on('page.dt', function() {
        var info = table.page.info();


        console.log('Showing page: ' + info.page + ' of ' + info.pages);
      });


    });
  }


  reloadPage() {
    window.location.reload();
  }


}
