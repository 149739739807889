import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { FormGroup, FormControl } from '@angular/forms';
import {DynamicScriptLoaderService} from '../services/dynamic-script-loader.service'
declare var $:any
@Component({
  selector: 'app-yoneticiler',
  templateUrl: './yoneticiler.component.html',
  styleUrls: ['./yoneticiler.component.scss']
})


export class YoneticilerComponent implements OnInit {
  basicForm: FormGroup;
  floatingForm: FormGroup;
yonetici:any=[{
  sicilNo:"Aster-01",
  adi:"Aster",
  soyadi:"Textile",
  yetki:"Admin"
}]
yoneticiEkleme:any={yetki:false}
yetki:any=["Giriş","Yönetici","Admin"]
ekle:any={yetki:"giris",adi:"das",soyadi:"das",sicilNo:1}



  constructor( private httpService: HttpService , private dynamicScriptLoader: DynamicScriptLoaderService ) { }

async  ngOnInit() {

  $(document).ready(function(){
    // Show the Modal on load
    $("#myModal").modal("show");
      
    // Hide the Modal
    // $("#myBtn").click(function(){
    //   $("#myModal").modal("hide");
    // });
  });
  this.basicForm = new FormGroup({
    yoneticiAdi: new FormControl(''),
    yoneticiSoyadi: new FormControl(''),
  });

   this.floatingForm = new FormGroup({
     yoneticiAdi: new FormControl(''),
     yoneticiSoyadi: new FormControl(''),
     sicilNo: new FormControl(''),
     // yetki: new FormControl(''),
     gorevi: new FormControl(''),
     telefon: new FormControl(''),
     sifre: new FormControl(''),
     email: new FormControl(''),
   });

var email = new FormControl('some value');


// this.getYonetici();

await this.dynamicScriptLoader.load('form.min').then(() => {
      // var yetki=["Giriş","Yönetici","Admin"]
    }).catch(error => console.log(error));

$(".selectYetki").select2({
      language: "tr",
      data:this.yetki
    }).on("change",(e:any)=>{
                              this.ekle.yetki = this.yetki});



  }




getYonetici(){
  this.httpService.getYonetici({}).subscribe(
    (data: any) => {
      if (data.error) {
        alert(data.error)

      } else {

        console.log("get yonetici")
        console.log(data);
        this.yonetici = data;

      }
    }
  )
}

yoneticiEkle(item){

console.log(item)

this.floatingForm.setValue({
yoneticiAdi:item.adi,
yoneticiSoyadi:'anan',
sicilNo:'31',
gorevi:"yetkili",
telefon:"123",
sifre:"123",
email:"123"
})

console.log(this.floatingForm)
  this.httpService.yoneticiEkle(item).subscribe(
    (data: any) => {
      if (data.error) {
        console.log(data.error)

      } else {

        console.log("yonetici ekle")
        console.log(data);
        this.yonetici = data;
  //console.log(this.personelInfo.bilgi[0].iseBaslamaTarihi)
        //this.tableDraw(this.personelInfo.bilgi)

      }
    }
  )
}

}
