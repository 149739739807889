import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FileUploadService} from '../file-upload.service'
import {HttpService } from '../http.service'
import { HttpClient , HttpRequest, HttpResponse, HttpEventType } from '@angular/common/http';
import { FileUploader } from 'ng2-file-upload';
import { convertPdfToPng } from 'convert-pdf-png';
// import { type } from 'os';
declare const $: any;
declare const M: any;
declare const flatpickr: any;
@Component({
  selector: 'app-baski',
  templateUrl: './baski.component.html',
  styleUrls: ['./baski.component.scss']
})
export class BaskiComponent implements OnInit {

  // public uploader:FileUploader = new FileUploader({url: URL});
  basicForm: FormGroup;
  uploadedFiles: Array<File>;
  fileToUpload: File = null;
  fileUploadUrl = 'http://mesai.taglette.com:4100/uploadDesignFile';

  uploader:FileUploader;
  hasBaseDropZoneOver:boolean;
  hasAnotherDropZoneOver:boolean;
  response:string;
  yuklenenDosyalar:any=[]


  percentDone: number;
  uploadSuccess: boolean;
  isUploadActive=0;
  isSiparisEkleActive=0;
  siparisler:any=[];
  totalData = 0;
  searchText = "";
  pageNo = 1;
  nPerPage = 100;
  loading = false;

  silinecekSiparis
  duzenlenecekSiparis:any={}
  minDate:any={
    day:"",
    month:"",
    year:""
  }
  today:any

  siparis:any = {
    musteri:"",
    modelAdi:"",
    siparisNo:"",
    orderNo:"",
    varyant:[],
    operasyonlar:[{}],
    tarih:new Date()
  }
  varyantArray:any =[
    {varyant:"",
    termin:"",
    beden:"",
    adet:"",
    pastal:"",
    baskiOnYuz:"",
    baskiArkaYuz:"",
    nakisOnYuz:"",
    nakisArkaYuz:""
    }

   
  ]

  guncellenenVaryant:any=[
    {varyant:"",
    termin:"",
    beden:"",
    adet:"",
    pastal:"",
    baskiOnYuz:"",
    baskiArkaYuz:"",
    nakisOnYuz:"",
    nakisArkaYuz:""
    } 
  ]
  constructor(private fileUploadService:FileUploadService,private http:HttpClient,private httpService:HttpService) {
   }

  ngOnInit() {
    this.getSiparis()
    this.basicForm = new FormGroup({
      username: new FormControl(''),
      password: new FormControl(''),
    });
    this.today = new Date()
  }

  upload(files: File[],index,id){
    this.isUploadActive = 1
    this.uploadAndProgress(files,index,id);
  }

  uploadGuncelle(files: File[],index,id){
    this.isUploadActive = 1

    this.uploadAndProgress2(files,index,id);
  }

  uploadAndProgress(files: File[],index,id){
    var formData = new FormData();
    Array.from(files).forEach(f => formData.append('file',f))
    switch(id) { 
            case "pastal": { 
              this.varyantArray[index].pastal = "yukleniyor"
              
               break; 
            } 
            case "baskiOn": { 
              this.varyantArray[index].baskiOnYuz = "yukleniyor"
             
               break; 
            } 
            case "baskiArka": {
              this.varyantArray[index].baskiArkaYuz = "yukleniyor"
             
               break;    
            } 
            case "nakisOn": { 
              this.varyantArray[index].nakisOnYuz = "yukleniyor"
               
               break; 
            }  
           case "nakisArka":{
            this.varyantArray[index].nakisArkaYuz = "yukleniyor"
            
             break;
           } 
          }

  
    this.http.post(this.fileUploadUrl, formData, {reportProgress: true, observe: 'events'})
      .subscribe((event:any) => {   
        if (event.type === HttpEventType.UploadProgress) {
          this.percentDone = Math.round(100 * event.loaded / event.total);     
        } else if (event instanceof HttpResponse) {
          var body = event  
          switch(id) { 
            case "pastal": { 
              this.varyantArray[index].pastal = event.body.fileName
              
               break; 
            } 
            case "baskiOn": { 
              this.varyantArray[index].baskiOnYuz = event.body.fileName
              
               break; 
            } 
            case "baskiArka": {
              this.varyantArray[index].baskiArkaYuz = event.body.fileName
              
               break;    
            } 
            case "nakisOn": { 
              this.varyantArray[index].nakisOnYuz = event.body.fileName
               
               break; 
            }  
           case "nakisArka":{
            this.varyantArray[index].nakisArkaYuz = event.body.fileName
            
             break;
           } 
          }
          this.isUploadActive=0
          this.uploadSuccess = true;
        }
    },error =>{
      if(error.error){
        this.notifications(error.error)
      }
    });
  }

  uploadAndProgress2(files: File[],index,id){

 
    var formData = new FormData();
    Array.from(files).forEach(f => formData.append('file',f))
    switch(id) { 
            case "pastal": { 
              this.duzenlenecekSiparis.varyant[index].pastal = "yukleniyor"
               break; 
            } 
            case "baskiOn": { 
              this.duzenlenecekSiparis.varyant[index].baskiOnYuz = "yukleniyor"
             
               break; 
            } 
            case "baskiArka": {
              this.duzenlenecekSiparis.varyant[index].baskiArkaYuz = "yukleniyor"
             
               break;    
            } 
            case "nakisOn": { 
              this.duzenlenecekSiparis.varyant[index].nakisOnYuz = "yukleniyor"
               
               break; 
            }  
           case "nakisArka":{
            this.duzenlenecekSiparis.varyant[index].nakisArkaYuz = "yukleniyor"
            
             break;
           } 
          }

  
    this.http.post(this.fileUploadUrl, formData, {reportProgress: true, observe: 'events'})
      .subscribe((event:any) => {   
        if (event.type === HttpEventType.UploadProgress) {
          this.percentDone = Math.round(100 * event.loaded / event.total);     
        } else if (event instanceof HttpResponse) {
          var body = event  
          switch(id) { 
            case "pastal": { 
              this.duzenlenecekSiparis.varyant[index].pastal = event.body.fileName
             
               break; 
            } 
            case "baskiOn": { 
              this.duzenlenecekSiparis.varyant[index].baskiOnYuz = event.body.fileName
             
               break; 
            } 
            case "baskiArka": {
              this.duzenlenecekSiparis.varyant[index].baskiArkaYuz = event.body.fileName
              
               break;    
            } 
            case "nakisOn": { 
              this.duzenlenecekSiparis.varyant[index].nakisOnYuz = event.body.fileName
              
               break; 
            }  
           case "nakisArka":{
            this.duzenlenecekSiparis.varyant[index].nakisArkaYuz = event.body.fileName
            
             break;
           } 
          }
          this.isUploadActive=0
          this.uploadSuccess = true;
        }
    },error =>{
      if(error.error){
        this.notifications(error.error)
      }
    });
  }
  
varyantEkle(){
  this.varyantArray.push({
  varyant:"",
  termin:"",
  beden:"",
  adet:"",
  pastal:"",
  baskiOnYuz:"",
  baskiArkaYuz:"",
  nakisOnYuz:"",
  nakisArkaYuz:""
  })

}

varyantGuncelleyeEkle(){
  this.duzenlenecekSiparis.varyant.push({
    varyant:"",
    termin:"",
    beden:"",
    adet:"",
    pastal:"",
    baskiOnYuz:"",
    baskiArkaYuz:"",
    nakisOnYuz:"",
    nakisArkaYuz:""
  })
}

varyantSil(index){
  this.varyantArray.splice(index,1)
}

varyantGuncelleSil(index){
  this.duzenlenecekSiparis.varyant.splice(index,1)
}

siparisEkle(){

function checkForDuplicates(array) {
  let valuesAlreadySeen = []

  for (let i = 0; i < array.length; i++) {
    let value = array[i].varyant
    if (valuesAlreadySeen.indexOf(value) !== -1) {
      return true
    }
    valuesAlreadySeen.push(value)
  }
  return false
}

var checkForDuplicatesValue = checkForDuplicates(this.varyantArray)
if(checkForDuplicatesValue == true){
  this.notifications("Sipariş içerisinde aynı isimde varyantlar olamaz")
}else if(checkForDuplicatesValue==false){
  this.siparis.varyant = this.varyantArray
   
  for (let i = 0; i < this.siparis.varyant.length; i++) {
    if(this.siparis.varyant[i].termin == ""){
      var varyantTerminMesaji="Varyantların Termin Süreleri Boş bırakılamaz"
      return this.notifications(varyantTerminMesaji)
    }
    this.siparis.varyant[i].termin = new Date(this.siparis.varyant[i].termin)
    this.siparis.varyant[i].termin = this.siparis.varyant[i].termin.toISOString()
    
  }
  this.siparisGonder(this.siparis)
}
  
  

}

getSiparis(pageNo = 1){
  this.pageNo = pageNo
  this.loading = true;
  let search = undefined;
  if(this.searchText != ""){
    search = this.searchText;
  }
  this.httpService.getSiparis(this.nPerPage,this.pageNo,search).subscribe(
    (data:any)=>{
      this.loading = false;
      if(data.error){
        this.notifications(data.error)
      }else{
        this.siparisler = data.data;
        this.totalData = data.toplam;
        for(let i =0 ; i< this.siparisler.length;i++){ 
          for (let j = 0; j < this.siparisler[i].varyant.length; j++) {
            this.siparisler[i].varyant[j].termin = new Date(this.siparisler[i].varyant[j].termin)
            var day = this.siparisler[i].varyant[j].termin.getDate()
            var month = this.siparisler[i].varyant[j].termin.getMonth()+1
            var year = this.siparisler[i].varyant[j].termin.getFullYear()

            if(day <10){
              day = day.toString()
              day = "0"+day
            }else{
              day = day.toString()
            }
            if(month < 10){
              month = month.toString()
              month = "0" + month
            }else{
              month=month.toString()
            }
            year = year.toString()
            var date = year + "-" + month + "-" + day
            this.siparisler[i].varyant[j].termin = date
          }
        }
      }
    }
  )
}

siparisGonder(siparis){
  
  
    this.httpService.siparisEkle(siparis).subscribe(
      (data:any)=>{
        if(data.error){
          this.notifications(data.error)
        }else{
          
          $(document).ready(function(){     
            $("#siparisEkleModal").modal("hide");
          });
          this.getSiparis()
        }
      }
    )
}

siparisBelirle(silinecekSiparis){
this.silinecekSiparis = this.siparisler[silinecekSiparis]._id
}

siparisDuzenle(duzenlenecekSiparis){
this.duzenlenecekSiparis.bitisTarihi = this.siparisler[duzenlenecekSiparis].bitisTarihi
this.duzenlenecekSiparis.modelAdi = this.siparisler[duzenlenecekSiparis].modelAdi
this.duzenlenecekSiparis.musteri = this.siparisler[duzenlenecekSiparis].musteri
this.duzenlenecekSiparis.operasyonlar = this.siparisler[duzenlenecekSiparis].operasyonlar
this.duzenlenecekSiparis.orderNo = this.siparisler[duzenlenecekSiparis].orderNo
this.duzenlenecekSiparis.siparisNo = this.siparisler[duzenlenecekSiparis].siparisNo
this.duzenlenecekSiparis.tarih = this.siparisler[duzenlenecekSiparis].tarih
this.duzenlenecekSiparis.varyant = this.siparisler[duzenlenecekSiparis].varyant
this.duzenlenecekSiparis._id = this.siparisler[duzenlenecekSiparis]._id

}

siparisGuncelle(){
  
function checkForDuplicates(array) {
  let valuesAlreadySeen = []

  for (let i = 0; i < array.length; i++) {
    let value = array[i].varyant
    if (valuesAlreadySeen.indexOf(value) !== -1) {
      return true
    }
    valuesAlreadySeen.push(value)
  }
  return false
}

var checkForDuplicatesValue = checkForDuplicates(this.duzenlenecekSiparis.varyant)
if(checkForDuplicatesValue == true){
  this.notifications("Sipariş içerisinde aynı isimde varyantlar olamaz")
}else if(checkForDuplicatesValue==false){
  this.siparis.varyant = this.varyantArray
   
  for (let i = 0; i < this.duzenlenecekSiparis.varyant.length; i++) {
    if(this.duzenlenecekSiparis.varyant[i].termin == ""){
      var varyantTerminMesaji="Varyantların Termin Süreleri Boş bırakılamaz"
      return this.notifications(varyantTerminMesaji)
    }
    this.duzenlenecekSiparis.varyant[i].termin = new Date(this.duzenlenecekSiparis.varyant[i].termin)
    this.duzenlenecekSiparis.varyant[i].termin = this.duzenlenecekSiparis.varyant[i].termin.toISOString()
    
  }
  this.siparisGuncelleGonder(this.duzenlenecekSiparis)
}
  
  
}

siparisGuncelleGonder(item){
  this.httpService.siparisGuncelle(item).subscribe(
    (data:any)=>{
      if(data.error){
        this.notifications("Sipariş güncellenirken bir hata oluştu")
      }else{
        $(document).ready(function(){     
          $("#siparisDuzenle").modal("hide");
        });
        this.getSiparis()
      }
    }
  )
}


siparisSil(){
  this.httpService.siparisSil(this.silinecekSiparis).subscribe(
    (data:any)=>{
      if(data.error){
        alert(data.error)
      }else{
        $(document).ready(function(){     
          $("#siparisSil").modal("hide");
        });
        this.getSiparis()
      }
    }
  )
}



notifications(mesaj){
  'use strict';

                var placementFrom = "top"
                var placementAlign = "right"
                var animateEnter = ""
                var animateExit = ""
                var colorName = "alert-danger"

                showNotification(colorName, null, placementFrom, placementAlign, animateEnter, animateExit);
        

        function showNotification(colorName, text, placementFrom, placementAlign, animateEnter, animateExit) {
            if (colorName === null || colorName === '') { colorName = 'bg-black'; }
            if (text === null || text === '') { text = mesaj; }
            if (animateEnter === null || animateEnter === '') { animateEnter = 'animated fadeInDown'; }
            if (animateExit === null || animateExit === '') { animateExit = 'animated fadeOutUp'; }
            var allowDismiss = true;

            $.notify({
                message: text
            },
            
                {
                    type: colorName,
                    allow_dismiss: allowDismiss,
                    newest_on_top: true,
                    timer: 1000, // default   1000
                    z_index:9999,
                    placement: {
                        from: placementFrom,
                        align: placementAlign
                    },
                    animate: {
                        enter: animateEnter,
                        exit: animateExit
                    },
                    template: '<div data-notify="container" id="notify" style="z-index: 99999 !important;"class="bootstrap-notify-container snack alert alert-dismissible {0} ' + (allowDismiss ? "p-r-35" : "") + '" role="alert">' +
                        
                        '<span data-notify="icon"></span> ' +
                        '<span data-notify="title">{1}</span> ' +
                        '<span data-notify="message">{2}</span>' +
                        '<div class="progress" data-notify="progressbar">' +
                        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                        '</div>' +
                        '<a href="{3}" target="{4}" data-notify="url"></a>' +
                        '</div>'
                });

                document.getElementById("notify").style.zIndex = "9999";
              //   $( window ).ready(function() {
              //     document.getElementById("notify").style.zIndex = "9999";
              //     // var x = document.getElementsByClassName("notify")[0].id;
              //     
              // });
              
        }
}


}
