import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpService } from '../http.service'
declare var echarts:any;
declare var $:any;
@Component({
  selector: 'app-bantlar',
  templateUrl: './bantlar.component.html',
  styleUrls: ['./bantlar.component.scss']
})
export class BantlarComponent implements OnInit {

  constructor(private httpService:HttpService, private route:ActivatedRoute) { }
chart:any
  ngOnInit() {

    $(document).ready(function(){
      // Show the Modal on load
      $("#myModal").modal("show");
        
      // Hide the Modal
      // $("#myBtn").click(function(){
      //   $("#myModal").modal("hide");
      // });
    });

    this.route.paramMap.subscribe((p:any) => {
      
});
    this.pieChart();
    this.lineChart();
    this.uretimPlani();

    $(".dial").knob({
      'min': 0,
      'max': 100,
      'thickness': 0.1,
'format':function(value){return value + "%"},
'draw': function() {
        $(this.i).css('font-size', '30px');
      }
    });
    $(".knob").knob({
      draw: function () {
        if ("tron" == this.$.data("skin")) {
          this.cursorExt = .3;
          var t, i = this.arc(this.cv);
          return this.g.lineWidth = this.lineWidth, this.o.displayPrevious && (t = this.arc(this.v), this.g.beginPath(), this.g.strokeStyle = this.pColor, this.g.arc(this.xy, this.xy, this.radius - this.lineWidth, t.s, t.e, t.d), this.g.stroke()), this.g.beginPath(), this.g.strokeStyle = this.o.fgColor, this.g.arc(this.xy, this.xy, this.radius - this.lineWidth, i.s, i.e, i.d), this.g.stroke(), this.g.lineWidth = 2, this.g.beginPath(), this.g.strokeStyle = this.o.fgColor, this.g.arc(this.xy, this.xy, this.radius - this.lineWidth + 1 + 2 * this.lineWidth / 3, 0, 2 * Math.PI, !1), this.g.stroke(), !1
        }
      }
    });


    'use strict';
    $('#basic_demo').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 3
        },
        1000: {
          items: 5
        }
      }
    });
    $('#single_slide').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      items: 1,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
      smartSpeed: 450
    });
    $('#single_slide_autoplay').owlCarousel({
      items: 1,
      loop: true,
      margin: 10,
      autoplay: true,
      autoplayTimeout: 3000,
      autoplayHoverPause: true
    });
    $('.play').on('click', function () {
      $('#single_slide_autoplay').trigger('play.owl.autoplay', [3000])
    })
    $('.stop').on('click', function () {
      $('#single_slide_autoplay').trigger('stop.owl.autoplay')
    })
    $('#withloop').owlCarousel({
      center: true,
      items: 2,
      loop: true,
      margin: 10,
      responsive: {
        600: {
          items: 4
        }
      }
    });
    $('#nonloop').owlCarousel({
      center: true,
      items: 2,
      loop: false,
      margin: 10,
      responsive: {
        600: {
          items: 4
        }
      }
    });
    $('#dashboard_slide').owlCarousel({
      items: 1,
      loop: true,
      margin: 10,
      autoplay: false,
      autoplayTimeout: 2000,
      dots: false,
      autoplayHoverPause: true
    });
    $('#dashboard_slide2').owlCarousel({
      items: 1,
      loop: true,
      margin: 10,
      autoplay: true,
      autoplayTimeout: 3000,
      dots: false,
      autoplayHoverPause: true
    });

  }



uretimPlani() {


// let item = 0
//     this.httpService.getUretimPlani(item).subscribe(
//       (data: any) => {
//         if (data.error) {
//           alert(data.error)
//         }
//         else {
//
//
//         
//
//         }
//
//       }
//     )
  }

pieChart(){

  let q = this

  let option = {

    tooltip : {
        trigger: 'item',
        formatter: "{b} : {c} ({d}%)"
    },
    legend: {
        orient: 'horizontal',
        left: 'left',
        data: ['Kalan','Biten',]
    },
    series : [
        {
           color:['#ffc107','#20c997'],
            type: 'pie',
            radius : '55%',
            center: ['50%', '60%'],
            data:[
                {value:0, name:'Kalan'},
                {value:0, name:'Biten'},

            ],
            itemStyle: {
                emphasis: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
  };

  this.chart = echarts.init(document.getElementById('pieChart'));


  this.chart.setOption(option);

  }

lineChart(){
  let q = this

  var colors = ['#5793f3', '#d14a61', '#675bba'];


  let option = {
    color: colors,

    tooltip: {
        trigger: 'none',
        axisPointer: {
            type: 'cross'
        }
    },
    legend: {
        data:['Hedef', 'Üretim'],
        position:'bottom'
    },
    grid: {
        top: 70,
        bottom: 50,
        right:30,
        left:30
    },
    xAxis: [
        {
            type: 'category',
            axisTick: {
                alignWithLabel: true
            },
            axisLine: {
                onZero: false,
                lineStyle: {
                    color: colors[1]
                }
            },
            axisPointer: {
                label: {
                    formatter: function (params) {
                        return 'Hedef  ' + params.value
                            + (params.seriesData.length ? '：' + params.seriesData[0].data : '');
                    }
                }
            },
            data: ["1","2","3","4","5"]
        },
        {
            type: 'category',
            axisTick: {
                alignWithLabel: true
            },
            axisLine: {
                onZero: false,
                lineStyle: {
                    color: colors[0]
                }
            },
            axisPointer: {
                label: {
                    formatter: function (params) {
                        return 'Üretim  ' + params.value
                            + (params.seriesData.length ? '：' + params.seriesData[0].data : '');
                    }
                }
            },
            data: ["1","2","3","4","5"]
        }
    ],
    yAxis: [
        {
            type: 'value'
        }
    ],
    series: [
        {
            name:'Hedef',
            type:'line',
            xAxisIndex: 1,
            smooth: true,
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
            name:'Üretim',
            type:'line',
            smooth: true,
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
    ]
  };

  this.chart = echarts.init(document.getElementById('lineChart'));


  this.chart.setOption(option);

  }


}
