import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./layout/header/header.component";
import { PageLoaderComponent } from "./layout/page-loader/page-loader.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { RightSidebarComponent } from "./layout/right-sidebar/right-sidebar.component";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { DynamicScriptLoaderService } from "./services/dynamic-script-loader.service";
import { HttpClientModule } from '@angular/common/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MakinelerComponent } from './makineler/makineler.component';
// import {SignupComponent} from './authentication/signup/signup.component';
import { PersonellerComponent } from './personeller/personeller.component';
import { YoneticilerComponent } from './yoneticiler/yoneticiler.component';
import { CalismasaatleriComponent } from './calismasaatleri/calismasaatleri.component';
import { KayipzamanlarComponent } from './kayipzamanlar/kayipzamanlar.component';
import { BantlarComponent } from './bantlar/bantlar.component';
import { BaskiComponent } from './baski/baski.component';
import { FileUploadModule } from 'ng2-file-upload'


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    MakinelerComponent,
    PersonellerComponent,
    YoneticilerComponent,
    CalismasaatleriComponent,
    KayipzamanlarComponent,
    BantlarComponent,
    BaskiComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    FileUploadModule,

    HttpClientModule,
    FormsModule,
    NgxDatatableModule

  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DynamicScriptLoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
