import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MakinelerComponent} from './makineler/makineler.component'
import {PersonellerComponent} from './personeller/personeller.component'
import {YoneticilerComponent} from './yoneticiler/yoneticiler.component'
import {CalismasaatleriComponent} from './calismasaatleri/calismasaatleri.component'
import {KayipzamanlarComponent} from './kayipzamanlar/kayipzamanlar.component'
import {BantlarComponent} from './bantlar/bantlar.component'
import { BaskiComponent } from './baski/baski.component';
import {SignupComponent} from './authentication/signup/signup.component'

const routes: Routes = [
  {
      path: '',
      redirectTo: 'authentication/signin',
      pathMatch: 'full'
  },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'email',
        loadChildren: () => import('./email/email.module').then(m => m.EmailModule)
    },
    {
        path: 'apps',
        loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule)
    },
    {
        path: 'widget',
        loadChildren: () => import('./widget/widget.module').then(m => m.WidgetModule)
    },
    {
        path: 'ui',
        loadChildren: () => import('./ui/ui.module').then(m => m.UiModule)
    },
    {
        path: 'forms',
        loadChildren: () => import('./forms/forms.module').then(m => m.FormModule)
    },
    {
        path: 'tables',
        loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule)
    },
    {
        path: 'media',
        loadChildren: () => import('./media/media.module').then(m => m.MediaModule)
    },
    {
        path: 'charts',
        loadChildren: () => import('./charts/charts.module').then(m => m.ChartsModule)
    },
    {
        path: 'timeline',
        loadChildren: () => import('./timeline/timeline.module').then(m => m.TimelineModule)
    },
    {
        path: 'icons',
        loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule)
    },
    {
        path: 'authentication',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    {
        path: 'extra-pages',
        loadChildren: () => import('./extra-pages/extra-pages.module').then(m => m.ExtraPagesModule)
    },
    {
        path: 'maps',
        loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule)
    },

    {path:'makineler' , component:MakinelerComponent},
    {path:'personeller' , component:PersonellerComponent},
    {path:'yoneticiler' , component:YoneticilerComponent},
    {path:'calismasaatleri' , component:CalismasaatleriComponent},
    {path:'kayipzamanlar' , component:KayipzamanlarComponent},
    {path:'baski' , component:BaskiComponent},
    // {path:'bantlar/:id' , component:BantlarComponent},
    {path:'bantlar' , component:BantlarComponent},
    // {path:'kayitol',component:SignupComponent}

    {
        path: '*',
        redirectTo: 'authentication/signin',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
