import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http'
import { StorageServicee } from './storage.service'
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  // root = 'http://localhost:4001' //dev.
  // root2 = 'http://localhost:4000'
  // root3 = 'http://localhost:4002'
  root = 'http://mesai.taglette.com:4100' //dev.
  root2 = 'http://mesai.taglette.com:4100'
  root3 = 'http://mesai.taglette.com:4100'
  // root = 'http://192.168.1.229:4001' //dev.
  // root2 = 'http://192.168.1.229:4000'
  // root3 = 'http://192.168.1.229:4002'
  // dataTableRoot = 'http://192.168.1.18:4200'
  //readonly ROOT = 'http://138.68.96.42:4000' //production

  constructor(private http: HttpClient, private storage: StorageServicee) { }

  dataTableArama(search) {

    return this.http.post(this.root + '/dataTable', {search}).pipe(
      (data: any): any => {
        if (data.error) {
          alert("data Table search error")
        } else {
          return data
        }

      }
    )
  }

  login(girisBilgi) {
    return this.http.post(this.root2 + '/login', girisBilgi)
      .pipe(
        (data: any): any => {
          if (data.error) {
            alert("Giriş yapılırken hata oluştu.")
          } else {
            return data
          }
        }
      )
  }

  kayitOl(kayitBilgi){
    return this.http.post(this.root2 + '/yoneticiGuncelle' ,kayitBilgi).pipe(
      (data:any):any =>{
        if(data.error){
          alert("kayıt Olunurken Hata Oluştu")
        }else{
          return data
        }
      }
    )
  }

  dataUpload(file:File){
    return this.http.post(this.root + '/veri',{file:file}).pipe(
      (data:any):any=>{
        if(data.error){
         
        }else{
          return data
        }
      }
    )
  }

  info() {
    var info
    return this.http.post(this.root2 + '/info', info)
      .pipe(
        (data: any): any => {
          if (data.error) {
            alert("info alınırken hata oluştu.")
          } else {
            return data
          }
        },
        catchError(e => throwError(e))
      )
  }

  getToken() {
    return new HttpHeaders().set("Authorization", "Bearer " + this.storage.getPersonelGirisi())
  }

  getSiparis(nPerPage,pageNo,search = null) {
    return this.http.post(this.root2 + '/getSiparis', { nPerPage: nPerPage, pageNo: pageNo, search:search }, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("Siparişler alınırken hata oluştu.")
        } else {
          return data
        }
      }
    )
  }

  siparisEkle(siparis){
    return this.http.post(this.root2 +'/siparisEkle',siparis,{headers: this.getToken()}).pipe(
      (data:any)=>{
        if(data.error){
          
        }else{
          return data
        }
      }
    )
  }
  siparisGuncelle(siparis){
    return this.http.post(this.root2+'/siparisGuncelle',siparis,{headers:this.getToken()}).pipe(
      (data:any)=>{
        if(data.error){
         
        }else{
          return data
        }
      }
    )
  }

  siparisSil(siparis){
   
    return this.http.post(this.root2 +'/siparisSil',{id:siparis},{headers: this.getToken()}).pipe(
      (data:any)=>{
        if(data.error){
       
        }else{
          return data
        }
      }
    )
  }

  // getSiparis(){
  // let token = this.storage.getPersonelGirisi()
  //   console.log(token)
  //   headersToken = new HttpHeaders().set("Authorization", "Bearer " + token)
  //   //console.log(headersToken)
  //
  //   return this.http.post(this.root +'/getSiparis',{headers: headersToken}).pipe(
  //     (data:any) =>{
  //       if(data.error){
  //         alert("Siparişler alınırken hata oluştu.")
  //       }else{
  //         console.log(data)
  //         return data
  //
  //       }
  //     }
  //   )
  // }

  ganttDataGonderPost(name) {

    return this.http.post(this.root3 + '/ekleGantt', name, { headers: this.getToken() })
      .pipe(
        (data: any): any => {
          if (data.error) {
            alert("info alınırken hata oluştu.")
          } else {
            return data
          }
        }
      )

  }


  ganttDataAlPost(item) {


    return this.http.post(this.root3 + '/getGantt', item, { headers: this.getToken() }).pipe(
      (data: any): any => {
        if (data.error) {
          alert(data.error)
        } else {
          return data
        }
      }
    )


  }

  tatilEkle(tatil) {
    return this.http.post(this.root + '/tatilEkle', tatil, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("tatil eklenirken hata oluştu")
        } else {
          return data
        }
      }
    )

  }
  tatilSil(tatil) {
    return this.http.post(this.root + '/tatilSil', tatil, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("tatil bilgileri silinirken hata oluştu")
        } else {
          return data
        }
      }
    )

  }
  tatilGuncelle(tatil) {
    return this.http.post(this.root + '/tatilGuncelle', tatil, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("tatil bilgileri güncellenirken hata oluştu")
        } else {
          return data
        }
      }
    )

  }

  haftalikCalismaProgrami(program) {
    return this.http.post(this.root + '/haftalikPlanEkle', program, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("haftalık plan eklenirken hata oluştu")
        } else {
          return data
        }
      }
    )

  }

  getMolaBilgileri(item) {
    return this.http.post(this.root + '/getMolaBilgileri', item, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert(data.error)
        } else {
          return data
        }
      }
    )
  }

  molaGuncelle(ekle) {
    return this.http.post(this.root + '/molaGuncelle', ekle, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert(data.error)
        } else {
          return data
        }
      }
    )

  }


  getPersonel(nPerPage: number, pageNo: number, search: string | number = "") {
    return this.http.post(this.root + '/getPersonel', { nPerPage: nPerPage, pageNo: pageNo, search: search }, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("personel bilgileri alınırken hata oluştu.")
        } else {
          return data
        }
      }
    )
  }


  personelEkle(personel) {
    
    return this.http.post(this.root + '/personelEkle', personel, { headers: this.getToken() }).pipe(
      (data: any): any => {
        if (data.error) {
          alert("Kayıt Edilemedi: " + data.err);
        } else {
          return data
        }
      }
    )
  }

  personelSil(personelId) {
    return this.http.post(this.root + '/personelSil', { _id: personelId }, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("personel silinirken hata oluştu")
        } else {
          return data
        }
      }
    )
  }

  personelGuncelle(personelGuncel) {
    return this.http.post(this.root + '/personelGuncelle', personelGuncel, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("personel güncellenirken hata oluştu.")
        } else {
          return data
        }
      }
    )
  }


  getOperasyon(nPerPage: number, pageNo: number, search: string | number = "") {
    return this.http.post(this.root + '/getOperasyon', { nPerPage: nPerPage, pageNo: pageNo, search: search }, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("Bant Çalışma bilgisi alınırken hata oluştu.")
        } else {
          return data
        }
      },
      catchError(e => throwError(e))
    )
  }

  operasyonEkle(operasyon) {
    return this.http.post(this.root + '/operasyonEkle', operasyon, { headers: this.getToken() }).pipe(
      (data: any): any => {
        if (data.error) {
          alert("Kayıt Edilemedi: " + data.err);
        } else {
          return data
        }
      }
    )
  }

  operasyonSil(operasyonId) {
    return this.http.post(this.root + '/operasyonSil', { _id: operasyonId }, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("Operasyon silinirken hata oluştu")
        } else {
          return data
        }
      }
    )
  }

  operasyonGuncelle(operasyonGuncel) {
    return this.http.post(this.root + '/operasyonGuncelle', operasyonGuncel, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("Operasyon güncellenirken hata oluştu.")
        } else {
          return data
        }
      },
      catchError(e => throwError(e))

    )
  }

  getMakine(nPerPage: number, pageNo: number, search: string | number = "") {
    return this.http.post(this.root + '/getMakine', { nPerPage: nPerPage, pageNo: pageNo, search: search }, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("Makine bilgileri alınırken hata oluştu.")
        } else {
          return data
        }
      }
    )
  }

  makineEkle(makine) {
    return this.http.post(this.root + '/makineEkle', makine, { headers: this.getToken() }).pipe(
      (data: any): any => {
        if (data.error) {
          alert("Kayıt Edilemedi: " + data.err);
        } else {
          return data
        }
      }
    )
  }

  makineSil(makineId) {
    return this.http.post(this.root + '/makineSil', { _id: makineId }, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("makine bilgileri silinirken hata oluştu")
        } else {
          return data
        }
      }
    )
  }

  makineGuncelle(makineGuncel) {
    return this.http.post(this.root + '/makineGuncelle', makineGuncel, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("makine bilgileri güncellenirken hata oluştu.")
        } else {
          return data
        }
      }
    )
  }

  makineCalismaSuresi(item) {
   
    return this.http.post(this.root + '/makineCalismaSuresi', item, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("calisma saatleri alınırken hata oluştu.")
        } else {
          return data
        }
      }
    )
  }

  getYonetici(item) {

    return this.http.post(this.root + '/getYonetici', item, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("calisma saatleri alınırken hata oluştu.")
        } else {
          return data
        }
      }
    )

  }

  yoneticiEkle(item) {

    return this.http.post(this.root + '/yoneticiEkle', item, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert("yönetici bilgileri eklenirken hata oluştu.")
        } else {
          return data
        }
      }
    )

  }



  getAnasayfa() {
    return this.http.post(this.root + '/anaSayfa', {}, { headers: this.getToken() }).pipe(
      (data: any) => {
        if (data.error) {
          alert(data.error)
        } else {

          return data
        }
      }
    )
  }



  // private handleError(error: any): Promise<any> {
  //   console.error('Sunucuya Bağlanırken Hata: ', error);
  //   if (error.statusText == "") {
  //     alert('Sunucuya Bağlanılamıyor. Lütfen Bağlantınızı kontrol ediniz. Sorun devam ediyorsa bizimle iletişime geçiniz.')
  //   }else{
  //     alert('Sunucuya Bağlanırken Hata:' + error.statusText)
  //   }
  //   return Promise.reject(error.message || error);
  // }
  //


}
//
// function errorHandling(e:any){
//
// console.log(e)
// if(e == "jwt ex"){}
// return "xd"
// }
