import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { retry, catchError } from 'rxjs/operators';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
 } from '@angular/common/http';
//  import 'rxjs/add/operator/catch';
//  import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private httpClient:HttpClient) { }

 

}
