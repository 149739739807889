import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageServicee {
personelBilgi:any={}
  constructor() { }

  setPersonelGirisi(item){
  localStorage.setItem("token",item.token)
  localStorage.setItem("kullaniciAdi",item.userData.adi)
  localStorage.setItem("soyadi",item.userData.soyadi)
  localStorage.setItem("yetki",item.userData.yetki)
  localStorage.setItem("sicilNo",item.userData.sicilNo)
  localStorage.setItem("email",item.userData.email)


}

getPersonel(){

this.personelBilgi.adi = localStorage.getItem("kullaniciAdi")
this.personelBilgi.soyadi = localStorage.getItem("soyadi")
this.personelBilgi.yetki = localStorage.getItem("yetki")
this.personelBilgi.sicilNo = localStorage.getItem("sicilNo")
this.personelBilgi.email = localStorage.getItem("email")

return this.personelBilgi

}

getUserName(){
  var kullaniciAdi:any = localStorage.getItem("kullaniciAdi")

  if (kullaniciAdi===null){
    kullaniciAdi={}
  }

    return kullaniciAdi
 }

getPersonelGirisi(){
  let personel:any = (localStorage.getItem("token"));
  if(personel === null){
    personel = []
  }
  return personel;
}
}
