import { Component, OnInit } from '@angular/core';
declare var echarts:any;
declare const $:any
@Component({
  selector: 'app-kayipzamanlar',
  templateUrl: './kayipzamanlar.component.html',
  styleUrls: ['./kayipzamanlar.component.sass']
})
export class KayipzamanlarComponent implements OnInit {

  chart:any
    constructor() { }

    ngOnInit() {

        $(document).ready(function(){
            // Show the Modal on load
            $("#myModal").modal("show");
              
            // Hide the Modal
            // $("#myBtn").click(function(){
            //   $("#myModal").modal("hide");
            // });
          });
// this.kayipZaman2();
    	// this.kayipZaman();
    	// this.pieChart();
    	// this.lineChart();
    }



  kayipZaman(){
   	var q = this



   let option = {

      title: {
                  text: "Yan Çatma" ,
                  subText:"Yan Çatma",
                  x: "center",
                  y: "center",
                  itemGap: 10,
                  textStyle: {
                      color: "#17a2b8",
                      fontSize:"15",
                      fontWeight: "500"
                  }
              },


      tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b}: {c} ({d}%)"
      },

      series: [
          {
              color:['#20c997','lightgrey'],
              name:'Verim',
              type:'pie',

              radius: ['60%', '70%'],
              avoidLabelOverlap: false,
              label: {
                  normal: {
                      show: false,
                      position: 'right'
                  },
                  emphasis: {
                      show: false,
                      textStyle: {
                          fontSize: '30',
                          fontWeight: 'bold'
                      }
                  }
              },
              labelLine: {
                  normal: {
                      show: false
                  }
              },
              data:[
                  {value:100, name:'直接访问'},
                  {value:0, name:'邮件营销'},

              ]
          }
      ]
  };
  this.chart = echarts.init(document.getElementById('verimChart'));

  this.chart.setOption(option);
    }
    // kayipZaman2(){
    //   var q = this
    //
    //
    //
    //  let option = {
    //
    //     title: {
    //                 text: "Yan Çatma" ,
    //                 subText:"Yan Çatma",
    //                 x: "center",
    //                 y: "center",
    //                 itemGap: 10,
    //                 textStyle: {
    //                     color: "#17a2b8",
    //                     fontSize:"15",
    //                     fontWeight: "500"
    //                 }
    //             },
    //
    //
    //     tooltip: {
    //         trigger: 'item',
    //         formatter: "{a} <br/>{b}: {c} ({d}%)"
    //     },
    //
    //     series: [
    //         {
    //             color:['#20c997','lightgrey'],
    //             name:'Verim',
    //             type:'pie',
    //
    //             radius: ['60%', '70%'],
    //             avoidLabelOverlap: false,
    //             label: {
    //                 normal: {
    //                     show: false,
    //                     position: 'right'
    //                 },
    //                 emphasis: {
    //                     show: false,
    //                     textStyle: {
    //                         fontSize: '30',
    //                         fontWeight: 'bold'
    //                     }
    //                 }
    //             },
    //             labelLine: {
    //                 normal: {
    //                     show: false
    //                 }
    //             },
    //             data:[
    //                 {value:100, name:'直接访问'},
    //                 {value:0, name:'邮件营销'},
    //
    //             ]
    //         }
    //     ]
    // };
    // this.chart = echarts.init(document.getElementById('verimChart2'));
    //
    // this.chart.setOption(option);
    //   }

      pieChart(){

  	let q = this

  	let option = {

      tooltip : {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)"
      },
      legend: {
          orient: 'horizontal',
          left: 'left',
          data: ['Duruş','Çalışma Süresi',]
      },
      series : [
          {
             color:['#ffc107','#20c997'],
              type: 'pie',
              radius : '80%',
              center: ['50%', '50%'],
              data:[
                  {value:335, name:'Duruş'},
                  {value:310, name:'Çalışma Süresi'},

              ],
              itemStyle: {
                  emphasis: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
              }
          }
      ]
  };

  this.chart = echarts.init(document.getElementById('pieChart'));


  this.chart.setOption(option);

  }

  lineChart(){

    	let q = this

    let option = {



    	grid: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  },
  tooltip : {

          trigger: 'item',
          formatter: "{a} </br>{b} : {c}"
      },

      backgroundColor:'#17a2b8',

      xAxis: {
          show:false ,
          type: 'category',
          boundaryGap: false,

      },
      yAxis: {
          type: 'value',
          show:false
      },
      series: [{
      	name:"Verim",
          smooth: true,
          color:'#fff',
          data: [820, 932, 901, 1000, 1290, 1330, 1320],
          type: 'line',
          areaStyle: {}
      }]
  };

  this.chart = echarts.init(document.getElementById('lineChart'));


  this.chart.setOption(option);

    }

}
